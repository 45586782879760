@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

:root {
  --primary-color: #f48c06;
  --secondary-color: #2f327d;
  --main-font: "Work Sans";
  --white-color: #fff;
  --outline-color: #a1aebf;
  --background-color1: #fffbef;
  --background-color2: #f2fffe;
}

*,
html,
body,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p,
body ul,
body li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  /* font-family: var(--main-font); */
  font-family: "Lato", sans-serif;
}

body #root {
  font-size: 16px;
  /* font-family: var(--main-font); */
  font-family: "Lato", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  background: #dcdcdc;
  height: 100%;
}

::-webkit-scrollbar-thumb {
  background: #2f327d;
  border-radius: 4px;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff !important;
  background: #2f327d !important;
}

::selection {
  color: #fff !important;
  background: #2f327d !important;
}

body {
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  height: auto;
}

body label {
  margin: 0;
}

footer {
  /* margin-top: auto; */
}

body ul,
body ol {
  list-style: none;
}

body img {
  max-width: 100%;
}

body a {
  text-decoration: none !important;
  color: #5168f4;
}

body a:hover,
body a:focus,
body a:active {
  text-decoration: none !important;
  outline: none;
}

body address {
  font-style: normal;
}

body textarea {
  resize: none;
}

body textarea:hover,
body textarea:focus,
body textarea:active {
  outline: none;
  box-shadow: none;
}

body input:hover,
body input:active,
body input:focus {
  outline: none;
  box-shadow: none;
}

body select:hover,
body select:active,
body select:focus {
  outline: none;
  box-shadow: none;
}

body input[type="number"] {
  -moz-appearance: textfield;
  /*For FireFox*/
}

body input[type="number"]::-webkit-inner-spin-button {
  /*For Webkits like Chrome and Safari*/
  -webkit-appearance: none;
  margin: 0;
}

body button,
body button:hover,
body button:focus,
body button:active {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

body img {
  border: none;
}

body a img {
  border: 0px;
}

body figure {
  margin: 0;
  padding: 0;
}

body .slick-initialized .slick-slide {
  outline: none;
  border: none;
}

div#root~iframe {
  display: none !important;
}

del {
  font-size: 14px;
  opacity: 0.5;
  margin-left: -6px;
}

.sign-in-opt-box {
  margin-top: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;
}

.sign-in-continue {
  position: relative;
  width: 100%;
}

.sign-in-line-box {
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
  width: 100%;
}

.sign-in-line {
  width: 100%;
  border-top: 1px solid #eee;
  /* z-index: 50; */
}

.sign-in-text-box {
  position: relative;
  display: flex;
  justify-content: center;
  color: #3a3a3a;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

.sign-in-text {
  background: white;
  padding: 0 40px;
}

.sign-in-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}

.custom-container {
  width: 100%;
  max-width: calc(1400px + 30px);
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.overflow-hidden {
  overflow: hidden !important;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.custom-col {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.outlet {
  padding: 0px;
  padding-top: 0px !important;
  background-color: white;
  width: 100%;
  padding: 0 0 0 250px;
}

.admin-user {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.admin-user .user-tham {
  height: 75px;
  width: 75px;
  border-radius: 5px;
  overflow: hidden;
}

.admin-user .user-tham img {
  width: 100%;
  height: 100%;
  /* background-image: url(../images/default-user-icon.png);s */
  object-fit: cover;
}

.admin-user .upload-img-btn {
  padding: 0 0 0 20px;
}

.admin-user .upload-img-btn .ant-form-item {
  margin-bottom: 0 !important;
}

.pro-gen-settings .from-group {
  padding: 0;
  margin: 0 0 40px;
}

.pro-gen-settings .from-group .ant-form-item {
  margin-bottom: 0 !important;
}

.pro-gen-settings .from-group .ant-form-item label {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #242424;
  padding: 0;
  margin: 0;
}

.pro-gen-settings .from-group .ant-input,
.pro-gen-settings .from-group .ant-select-selector {
  background: linear-gradient(0deg, #fafbfc, #fafbfc), #ffffff;
  border: 1px solid #e0e4ec;
  border-radius: 3px;
  height: 50px !important;
  padding: 10px;
  font-size: 14px;
  line-height: 1.2;
}

.pro-gen-settings .from-group .ant-select-selection-search-input {
  height: 50px !important;
}

.pro-gen-settings .from-group .ant-select-selection-item {
  padding: 10px 0;
  line-height: 1.6;
}

.pro-gen-settings .fr-panel {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 30px;
}

.pro-gen-settings .fr-panel .text-box {
  width: calc(100% - 44px);
  padding: 0 15px 0 0;
  margin: 0;
}

.pro-gen-settings .fr-panel .text-box h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #242424;
  padding: 0;
  margin: 0 0 10px;
}

.pro-gen-settings .fr-panel .text-box p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7a7a7a;
  padding: 0;
  margin: 0;
}

.switch-box .ant-switch .ant-switch-handle::before {
  background-color: #2f327d;
}

.password-settings .ant-input-password {
  background: linear-gradient(0deg, #fafbfc, #fafbfc), #ffffff;
  border: 1px solid #e0e4ec;
  border-radius: 3px;
  height: 50px !important;
}

.home {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 82px);
}

.home .home-left {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  width: calc(100% - 365px);
  max-width: calc(795px + 40px);
  padding-top: 32px;
  padding-bottom: 32px;
}

.home .home-left .welcome-banner {
  display: flex;
  background: url(../images/welcome-banner-illus.svg), #0077ff;
  background-repeat: no-repeat;
  background-position: bottom right 50px;
  background-size: 185px;
  margin-bottom: 50px;
  border-radius: 5px;
  padding: 18px 265px 32px 26px;
}

.home .home-left .welcome-banner .text-container .text-with-hand-icon {
  display: flex;
  align-items: flex-start;
  column-gap: 14px;
}

.home .home-left .welcome-banner .text-container .text-with-hand-icon .icon-container {
  flex: 0 0 auto;
}

.home .home-left .welcome-banner .text-container .text-with-hand-icon .icon-container img {}

.home .home-left .welcome-banner .text-container h3 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  margin: 0;
}

.home .home-left .welcome-banner .text-container p {
  color: #dbdbdb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.3px;
  margin: 12px 0 0;
}

.note-bold {
  font-weight: 600;
}

.card-details {
  border: 1px solid #a1aebf;
  border-radius: 3px;
  width: 500px;
  padding: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.master-card {
  display: flex;
  align-items: center;
}

.card_number {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delete-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.delete-icon img {
  width: 22px;
}

.home .home-left h3 {
  font-weight: 500;
  color: var(--secondary-color);
  margin-bottom: 20px;
  font-size: 24px;
}

.home .home-left .list-heading2 {
  color: #2f327d;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.home .home-left .exam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--outline-color);
  margin-bottom: 60px;
  box-sizing: border-box;
  transition: 0.5s;
}

.home .home-left .exam:hover {
  box-shadow: 1px 1px 12px gray;
}

.home .home-left .exam .exambtnpanel {
  display: flex;
  align-items: center;
}

.home .home-left .exam .examinfo {
  display: flex;
  width: calc(100% - 240px);
  align-items: center;
}

.home .home-left .exam .examinfo .infotext {
  width: calc(100% - 165px);
}

.image img {
  height: 80px;
}

.home .home-left .exam .exam-name {
  margin-left: 20px;
}

.home .home-left .exam .exam-name h2 {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

.home .home-left .exam .exam-name p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #a7afbd;
}

.home .home-left .exam .resume {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  height: 60px;
  margin-right: 20px;
}

.home .home-left .exam .resume p {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 2px;
}

.recommend-exam-lists {
  display: flex;
  flex-direction: column;
  row-gap: 21px;
}

.recommend-exam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.recommend-exam .recommend-exam-wrap {
  display: flex;
  width: calc(100% - 100px);
  padding: 0;
  margin: 0;
}

.recommend-exam .recommend-image {
  width: 183px;
  border: 1px solid #c8cdd6;
  text-align: center;
}

.recommend-exam .recommend-image img {
  max-width: 100%;
  padding: 10px;
}

.recommend-exam .recommend-info {
  width: calc(100% - 183px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recommend-exam .r-exam-name {
  margin-left: 20px;
  /* width: 40%; */
}

.recommend-exam .r-exam-name h2 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0px;
}

.recommend-exam .r-exam-name p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #a7afbd;
}

.buy {
  border: 1px solid var(--secondary-color);
  align-items: center;
  padding: 10px;
  width: 100px;
  height: 60px;
  border-radius: 5px;
  margin-right: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
}

.start-buy {
  color: white !important;
  background-color: var(--secondary-color);
}

.subscribe {
  border: 1px solid var(--secondary-color);
  align-items: center;
  padding: 10px;
  width: 100px;
  height: 60px;
  border-radius: 5px;
  margin-right: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #2f327d;
}

.subscribe:hover {
  background-color: #2f327d !important;
  color: #fff;
}

.subscribe:hover p {
  color: #fff !important;
}

.buy p {
  color: var(--secondary-color);
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 8px;
}

.home .home-right h1 {
  margin-bottom: 20px;
  color: #121212;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}

.home .home-right .average_score,
.home .home-right .registered_exam,
.home .home-right .years_completed {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--outline-color);
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 5px;
}

.home .home-right .average_score h1,
.home .home-right .registered_exam h1,
.home .home-right .years_completed h1 {
  margin-bottom: 0px;
  font-weight: 600;
}

.home .home-right .average_score p,
.home .home-right .registered_exam p,
.home .home-right .years_completed p {
  margin-bottom: 6px;
  color: #bdbdbd;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.home .home-right .average_score p,
.home .home-right .average_score h1,
.home .home-right .registered_exam p,
.home .home-right .registered_exam h1,
.home .home-right .years_completed p,
.home .home-right .years_completed h1 {
  margin-left: 10px;
}

.home .home-right .motivation {
  background-color: #edf0f6;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  position: relative;
  margin: 80px 0 0 0;
}

.home .home-right .motivation:after {
  content: "";
  background: url(/public/images/motivation-img.png) no-repeat left top;
  width: 52px;
  height: 90px;
  position: absolute;
  top: -40px;
  right: 35px;
}

.home-right {
  padding: 24px 20px 32px;
  width: 365px;
  border-left: 1px solid var(--outline-color);
}

.home .home-right .motivation h1 {
  letter-spacing: 0.343px;
  margin: 0 !important;
}

.home .home-right .motivation p {
  color: #404852c7;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.429px;
  margin-top: 8px;
}

.image {
  border: 25px solid #014823;
  width: 20%;
}

/* .exam-name {
    width: 40%
} */
.home-left .exam .image {
  text-align: center;
  width: 165px;
}

.profile-cursor {
  cursor: pointer;
}

.user-info-wrap {
  display: flex;
  padding: 0;
  margin: 0;
}

.user-info-wrap .user-info h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.user-info-wrap .user-info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #979ca5;
  padding: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
}

.signin-container .logo {
  width: 200px;
  display: block;
  margin: 0 auto 28px;
}

.signin-container h1 {
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
}

.signin-container h1.for-ambassador {
  font-size: 24px;
  letter-spacing: 0.8px;
}

.signin-container.for-ambassador .form-content-wrapper {
  padding: 46px 0 0;
}

.signin-container h1.for-ambassador~.signup_more_link {
  margin-top: 10px;
}

.signin-container .form-content-wrapper .reduced-t-gap {
  margin-top: -35px;
}

.signin-container .form-content-wrapper {
  padding: 60px 0 0;
  width: 100%;
}

.signin-container .form-content-wrapper .otpmodal {
  margin-top: -60px;
}

.ant-form-item-control-input-content {
  height: 48px !important;
}

.contact-us .ant-form-item-control-input-content {
  height: auto !important;
}

.ant-form-item.for-textarea .ant-form-item-control-input-content,
.ant-form-item.for-textarea .ant-form-item-control-input-content .ant-input {
  height: 110px !important;
}

.ant-form-item-control-input-content .ant-select-selector {
  height: 48px !important;
  border-radius: 3.786px !important;
}

.ant-form-item-control-input-content .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
  line-height: 41px !important;
}

.ant-form-item .ant-form-item-label>label {
  color: #777777;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.ant-form-item-control-input-content>input,
.ant-form-item-control-input-content>.ant-input-affix-wrapper {
  height: 100% !important;
  border-radius: 3.786px !important;
}

.ant-form-item-control-input-content input:-webkit-autofill,
.ant-form-item-control-input-content input:-webkit-autofill:hover,
.ant-form-item-control-input-content input:-webkit-autofill:focus,
.ant-form-item-control-input-content input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.ant-form-item-control-input-content input::placeholder {
  color: #868686;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signin-container .action-button-lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}

.signin-container .action-button-lists .auth-btn {
  height: 44px !important;
  width: 200px;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.signin-container .action-button-lists .auth-link-btn {
  height: unset !important;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.signup_more_link {
  color: #6b6b6b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.8%;
}

.signup_more_link a {
  color: #8934ad;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.one-line-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closebtn {
  position: absolute;
  right: 6px;
  top: 8px;
  z-index: 1;
  background-color: transparent;
}

.signup span a {
  font-size: 16px;
  margin-left: 5px;
  color: #2f327d;
  text-decoration: underline;
}

form {
  display: flex;
  flex-direction: column;
}

.email {
  background-color: white;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  height: 45px;
  margin-bottom: 15px;
  padding-left: 10px;
  outline: none;
}

.pass {
  background-color: white;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  height: 45px;
  margin-bottom: 5px;
  padding-left: 10px;
  outline: none;
}

.ant-input {
  padding: 8px 11px !important;
}

.login-form-forgot {
  color: #000 !important;
  text-decoration: underline !important;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  color: #393939;
  text-align: left;
  margin-bottom: 15px;
  line-height: 30px;
  font-size: 14px;
}

.subject {
  color: #393939;
  font-size: small;
  padding: 10px;
  display: flex;
  margin: 5px;
  border: 1px solid black;
}

.pr-20 {
  padding-right: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-60 {
  width: 60% !important;
}

.ml-10 {
  margin-left: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ant-tabs-tab {
  /* width: 200px !important; */
  justify-content: center;
  text-align: center;
  margin: 0 0 !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.ant-tabs-tab .forgot-password {
  float: right;
}

.remember-me {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 2px;
}

.remember-me a span {
  color: #000000;
}

.remember-me a {
  color: #000000;
  text-decoration-color: #000;
}

body .auth-btn {
  width: 100%;
  background-color: #2f327d;
  border: none;
  height: 50px;
  color: #fff;
  border-radius: 3px;
}

.auth-btn:disabled {
  cursor: not-allowed;
}

body .auth-link-btn {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #2f327d;
}

.save-btn {
  width: 100%;
  background-color: #2f327d;
  border: none;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  border-radius: 3px;
  width: 150px;
}

.warn-color {
  color: #ff0000;
}

.warn-btn {
  width: 100%;
  border: 1px solid #ff0000;
  background-color: transparent;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  color: #ff0000;
  border-radius: 3px;
  width: 150px;
  margin-left: 15px;
}

.ant-select-selector {
  min-height: 35px !important;
  padding: 2px 11px !important;
}

.ant-modal .ant-modal-footer {
  text-align: center !important;
  padding: 1px 0 0;
}

.ant-upload-list-text {
  display: none !important;
}

.anticon {
  /* color:#2F327D !important */
}

.ant-form-item-required {
  /* display: none !important; */
}

.ant-btn-primary {
  background-color: #2f327d !important;
  transition: 0.5s;
}

.ant-btn-primary:hover {
  box-shadow: 4px 5px 9px gray;
}

.ant-btn-default {
  border-color: #2f327d !important;
  color: #2f327d !important;
}

.ant-btn {
  height: 40px !important;
}

.font14 {
  font-size: 14px;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.wrap {
  flex-wrap: wrap;
}

.highlight-placeholder::placeholder {
  color: #9393a4;
  opacity: 1;
}

.highlight-placeholder:-ms-input-placeholder {
  color: #9393a4;
  /* Set the desired color for the placeholder for Microsoft Edge */
}

.highlight-placeholder::-ms-input-placeholder {
  color: #9393a4;
  /* Set the desired color for the placeholder for Microsoft Internet Explorer */
}

.sidebar {
  border-right: 1px solid #a1aebf;
  min-width: 250px;
  position: fixed;
  height: 100%;
  /* top: 90px;
    bottom: 0;
    left: 0; */
  background-color: #ffffff;
  padding-top: 40px;
}

.sidebar ul {
  list-style-type: none;
}

.sideitem {
  /* padding: 20px; */
  /* box-sizing: border-box; */
  height: 50px;
  width: 220px;
  font-size: 18px;
}

.sideitem .row {
  display: flex;
  flex-wrap: nowrap;
}

.sideitem a {
  text-decoration: none;
  color: #8a8a8a;
  padding: 10px 20px;
  border-radius: 5px 0px 0px 5px;
  border-left: 7px solid #fff;
  display: flex;
}

.sideitem a .manu-icon {
  padding: 0;
  margin: 0 10px 0 0;
  position: relative;
}

.sideitem a .manu-icon span {
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 1.2;
  top: -4px;
  right: -5px;
}

.sideitem a .manu-icon p {
  padding: 0;
  margin: 0;
}

.logout {
  text-decoration: none;
  color: #8a8a8a;
  padding: 10px 30px;
  border-radius: 5px 0px 0px 5px;
  border-left: 7px solid #fff;
  display: flex;
  cursor: pointer;
}

.modal-container {
  display: flex;
}

.sideitem .active {
  box-sizing: border-box;
  border-left: 7px solid #2f327d;
  color: #2f327d;
}

.real-home {
  display: flex;
  /* height: 100%; */
}

.error-msg {
  color: #ff4d4f !important;
  font-size: 14px !important;
  line-height: 1.5714285714285714 !important;
  text-align: left !important;
}

.input-box-error {
  border-color: #ff4d4f !important;
}

.site-height-wrap.for-student .header .user-info-wrap .tham {
  flex: 0 0 auto;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 0 !important;
  flex: 0 0 auto;
}

.other-exam-wrap img {
  /* width: 180px;
    height: 125px; */
  max-width: 100%;
}

.other-exam-wrap .exam-img {
  /* border : 1px solid #C8CDD6; */
}

.other-exam-wrap .exam-img {
  width: 183px;
  min-height: 126px;
  text-align: center;
  border: 1px solid #c8cdd6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.other-exam-wrap {
  display: flex;
  /* justify-content: space-between; */
}

.other-exams {
  /* display: flex;
    justify-content: space-between; */
  /* padding: 0 15px; */
}

/* .other-exams .exam-left {
    margin-right: 20px;
    padding-right: 20px;
} */

.other-exams .exam-left .other-exam-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.other-exams .exam-left .other-exam-wrap .r-exam-name {
  margin-left: 20px;
}

.other-exams .exam-left .other-exam-wrap .r-exam-name h2 {
  /* font-weight: 600;
    font-size: 17px; */
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #2b2b2b;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #8a8a8a !important;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2f327d !important;
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #2f327d !important;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #8a8a8a !important;
}

.ant-tabs-nav .ant-tabs-nav-wrap::before {
  display: none;
}

.other-exams .exam-left .other-exam-wrap .r-exam-name p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #a7afbd;
}

.other-exams .exam-left .other-exam-wrap .buy {
  /* border: 1px solid var(--secondary-color); */
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 20px;
  position: absolute;
  right: 0;
}

.other-exams .exam-left .other-exam-wrap .buy p {
  color: var(--secondary-color);
  font-size: 12px;
}

.d-flex {
  display: flex;
}

.desc {
  color: #8a8a8a !important;
  cursor: pointer;
  font-size: 12px;
}

.title {
  font-size: 16px !important;
}

.right-card {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

.right-card .blog-text {
  width: calc(100% - 150px);
  padding: 0 0 0 15px;
}

.w-40 {
  width: 42% !important;
}

.w-70 {
  width: 70% !important;
}

.notification-box {
  /* border: 0.8px solid grey; */
  border-left: 8px solid rgb(10, 10, 113);
  border-radius: 4px;
  padding: 10px;
  background: #f6fafb;
}

.unread {
  display: inline;
  float: right;
  color: red;
}

.headline {
  display: inline;
  float: left;
}

.message {
  display: inline-block;
  padding-top: 30px;
  font-weight: 400;
  width: 100px;
  color: grey;
  font-size: small;
}

.date {
  display: block;
  float: right;
  color: red;
}

.remember-text {
  font-size: 14px;
  color: #909090;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  margin-bottom: 20px;
}

.exam-card {
  border-radius: 5px;
  margin: 10px auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #c6c6c6;
  background-color: white;
  justify-content: space-between;
}

.icon {
  width: 15px;
  height: 15px;
  margin: 5px;
  margin-right: 10px;
}

.title {
  font-size: 16px;
  color: #0a0d13;
  font-weight: 500;
  line-height: 32px;
}

.desc {
  font-size: 12px;
  color: #909090;
  margin-top: 5px;
}

.footer-text {
  font-size: 12px;
  color: #ff0000;
  margin: 0px auto;
  width: 80%;
  max-width: 500px;
  text-align: center;
  line-height: 15px;
}

.exam-card {
  border-radius: 5px;
  margin: 10px auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #c6c6c6;
  background-color: white;
  justify-content: space-between;
  cursor: pointer;
}

.exam-card:hover {
  background-color: #f0f1ff;
}

.card-view {
  width: 400px;
  height: 400px;
  border: 1px solid #000;
}

.signin-container-wrap {
  padding: 45px 0 24px;
}

.signin-container {
  border-radius: 10px;
  margin: 0 auto 24px;
  width: calc(100% - 30px);
  max-width: 450px;
  max-height: 75dvh;
  overflow-y: scroll;
  padding: 42px 30px 45px;
  border: 1px solid #c6c6c6;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.signin-container .ant-form-vertical>div {
  text-align: center;
}

.signin-container .ant-form-vertical .ant-select-selector {
  text-align: left;
}

.signin-bottom {
  margin: 10px auto;
  margin-bottom: 20px;
  width: 80%;
  max-width: 500px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.signin-container-wrap .form-content-wrapper .exam-card .icon {
  margin-top: 9px;
}

.logo {
  width: 150px;
}

.profile-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-size: 100% 100%;
  margin-right: 10px;
}

.header {
  border-bottom: 1px solid #e5e5e5;
}

.header.stickyhead {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1;
}

.stickyheadGap {
  padding: 81px 0 0 0;
}

.name {
  font-weight: 500;
  font-size: 18px;
  color: #121212;
}

.email {
  font-size: 12px;
  color: #979ca5;
}

/* start: custom loader */
.loadingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 99999;
}

.loadingScreen.loader-spiner-remove .loaderBox {
  display: none !important;
}

.loadingScreen.loader-overlay-remove {
  background: transparent !important;
  visibility: hidden;
}

.loadingScreen.loader-overlay-remove .loaderBox {
  visibility: visible;
}

.inlineblock-loader-center {
  display: flex;
  justify-content: center;
}

.inlineblock-loader-center .loadingScreen {
  position: unset;
}

.inlineblock-sm-loader {
  display: inline-block;
}

.inlineblock-sm-loader .loadingScreen {
  position: unset;
}

.inlineblock-sm-loader .loadingScreen .loaderBox {
  width: 15px;
  height: 15px;
}

.loadingScreen.perticular-section {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loadingScreen .loaderBox {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid #5168f4;
  border-radius: 50%;
  border-top-color: #fff;
  -webkit-animation: loadingScreen__spin 2s linear infinite;
  /* Safari */
  animation: loadingScreen__spin 2s linear infinite;
}

.perticular-section-loading {
  position: relative;
}

.perticular-section-loading .loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Safari */
@-webkit-keyframes loadingScreen__spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loadingScreen__spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loader-center {
  position: relative !important;
}

.loader-center .loadingScreen {
  position: absolute !important;
}

.load-icon {
  display: flex;
  column-gap: 2px;
  flex: 0 0 auto;
}

.load-icon.center {
  justify-content: center;
  width: 100%;
  margin-top: 8px !important;
}

.load-icon span {
  display: inline-block;
  background-color: #000;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  flex: 0 0 auto;
  animation-name: loading_dots;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.load-icon span:nth-of-type(2) {
  animation-delay: 0.2s;
}

.load-icon span:nth-of-type(3) {
  animation-delay: 0.4s;
}

/* Keyframes */
@keyframes loading_dots {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* end: custom loader */
.subjectWrap {
  display: flex;
  flex-wrap: wrap;
}

.subjectWrap .subject {
  align-items: center;
  border: 1px solid #d9d9d9;
  padding-top: 5px;
  padding-bottom: 5px;
}

.jabm-sec {
  min-height: 100vh;
  padding: 120px 0 30px;
  display: flex;
  flex-direction: column;
}

.jabm-sec .jabm-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.jabm-sec .jabm-container .qucomm-btn-wrap {
  margin-top: auto;
}

.quttl {
  padding: 0;
  margin: 0 0 16px;
  color: #000;
  text-align: center;
  font-size: 47.57px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.jabm-sec .quTextboxa {
  max-width: 615px;
  margin: 0 auto;
  padding: 0 0 60px;
  width: 100%;
}

.qupara {
  padding: 0;
  margin: 0 0 15px;
  color: #393939;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.334px;
}

.radio-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
}

.radio-btn-wrap.center-radio {
  justify-content: center;
}

.radio-btn-wrap .btn-box input[type="radio"],
.radio-btn-wrap .btn-box input[type="checkbox"] {
  display: none;
}

.radio-btn-wrap .btn-box {
  padding: 0;
}

.radio-btn-wrap.center-radio .btn-box {
  margin-left: 15px;
}

.radio-btn-wrap .btn-box:last-child {
  margin-right: 0;
}

.radio-btn-wrap.center-radio .btn-box:last-child {
  margin-right: 15px;
}

.radio-btn-wrap .btn-box label {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #bcc8dc;
  border-radius: 3px;
  cursor: pointer;
}

.btn-box-footer input[type="radio"] {
  display: none;
}

.btn-box-footer {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  padding: 10px 15px;
  margin: 0;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 15px;
}

.btn-box-footerTask1 input[type="radio"] {
  display: none;
}

.btn-box-footerTask1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  background-color: #444444;
  color: white;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #bcc8dc;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 15px;
}

.radio-btn-wrap .btn-box input[type="radio"]:checked+label,
.radio-btn-wrap .btn-box input[type="checkbox"]:checked+label {
  color: #fff;
  border-color: #2f327d;
  background: #2f327d;
}

.qucomm-btn-wrap {
  padding: 60px 0 0;
}

.qucomm-btn-wrap .btn {
  padding: 5px 10px;
  background: #2f327d;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  width: 100%;
  max-width: 660px;
  height: 62px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.qucomm-btn-wrap .btn.size-ms {
  width: 248px;
  max-width: 100%;
  height: 55px;
}

.qucomm-btn-wrap .btn.big-btn {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
}

.exam-submitbtn {
  position: relative;
  bottom: 30px;
}

.quiListwrap {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 741px;
}

.quiListwrap h3 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 185%;
  padding: 0;
  margin: 0 0 30px;
}

.quiListwrap ul.list-style-number {
  list-style: decimal;
  list-style-position: inside;
}

.quiListwrap ul li {
  color: #565656;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 185%;
  padding: 0;
  margin: 0 0 6px;
}

.cusuploadbtn input[type="file"] {
  display: none;
}

.cusuploadbtn label {
  border: 1px solid #2f327d !important;
  color: #2f327d !important;
  background-color: #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: 0.5s;
}

.cusuploadbtn label:hover {
  box-shadow: -1px 2px 13px 0px #bdbdbe;
}

.jabm-container {
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.quSelect-panel-wrap {
  padding: 60px 0 0 0;
  margin: 0;
}

.quSelect-panel-wrap .quSelect-panel {
  padding: 0;
  margin: 0 0 60px;
}

.quSelect-panel-wrap .quSelect-panel h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 0;
  margin: 0 0 40px;
}

.exQulistwap {
  padding: 60px 0 0;
  margin: 0;
}

.exQulistwap h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 0;
  margin: 0 0 30px;
}

.exQucusupload {
  padding: 0;
  margin: 0;
}

.exQucusupload i {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #464646;
  padding: 0;
  margin: 0 0 15px;
  display: block;
}

.exQucusupload input[type="file"] {
  display: none;
}

.exQucusupload label {
  background: #ffffff;
  border: 1px dashed #e5e7eb;
  border-radius: 8px;
  width: 420px;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exQucusupload label img {
  max-width: 100%;
  max-height: 100%;
}

.cusradiolist [type="radio"]:checked,
.cusradiolist [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.cusradiolist [type="radio"]:checked+label,
.cusradiolist [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  padding-top: 3px;
  padding-bottom: 3px;
}

.cusradiolist [type="radio"]:checked+label:before,
.cusradiolist [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #9d9d9d;
  border-radius: 100%;
  background: #fff;
}

.cusradiolist [type="radio"]:checked+label:after,
.cusradiolist [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #3b82f6;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.cusradiolist [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cusradiolist [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.exQupanel {
  padding: 0 0 0;
  margin: 0 0 40px;
}

.exQupanel .qutext {
  padding: 0;
  margin: 0 0 30px;
}

.exQupanel .qutext h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2b2b2b;
  padding: 0;
  margin: 0;
}

.cusradiolist li {
  padding: 0;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}

.resultWrap {
  /* border: 1px solid #D5DDEC; */
  border-radius: 0;
  max-width: 650px;
  width: 100%;
  margin: 60px auto 20px;
  padding: 0;
}

.resultWrap .result-top {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 30px;
}

.resultWrap .result-top .box h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #2f327d;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.resultWrap .result-top .box h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #464646;
  padding: 0;
  margin: 0;
}

.resultWrap .result-mid {
  padding: 0;
  margin: 0;
}

.resultWrap .result-mid ul li {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 30px;
}

.resultWrap .result-mid ul li p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0;
}

.resultWrap .result-mid table {
  border: 1px solid #d5ddec;
}

.resultWrap .result-mid table td,
.resultWrap .result-mid table th {
  padding: 15px;
  border-bottom: 1px solid #d5ddec;
}

.resultWrap .result-mid table tr:last-child td {
  border-bottom: none;
}

.resultWrap .result-mid table tr td:last-child,
.resultWrap .result-mid table tr th:last-child {
  border-left: 1px solid #d5ddec;
}

.resultWrap .totalpanel {
  display: flex;
  justify-content: center;
  padding: 60px 0 40px;
  margin: 0;
}

.resultWrap .totalpanel span {
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000;
  padding: 0;
  margin: 0 10px 0 0;
}

.resultWrap .totalpanel strong {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.resultWrap .btns-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resultWrap .btns-panel .btn {
  padding: 20px 78px;
  background: #2f327d;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  outline: none;
  box-shadow: none;
  border: 1px solid #2f327d;
}

.resultWrap .btns-panel .btn:first-child {
  background: transparent;
  color: #2f327d;
}

.resulttl {
  padding: 80px 0 0 0;
  margin: 0;
  text-align: center;
}

.resulttl h2 {
  font-weight: 600;
  font-size: 47px;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  padding: 0 0 30px;
  margin: 0;
}

.resulttl h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  padding: 0 0 0px;
  margin: 0;
}

.helpModal {
  padding: 0;
  margin: 0;
}

.helpModal i {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #464646;
  padding: 0;
  margin: 0 0 20px;
  display: block;
}

.helpModal p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #2b2b2b;
  padding: 0;
  margin: 0;
}

.cusprogressbar {
  height: 4px;
  background: #ffc136;
  position: relative;
}

.cusprogressbar span {
  position: absolute;
  top: -12px;
  right: -20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: #615e83;
}

.stepWrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.cusradiolist li .anticon-close svg {
  color: #ff0000;
}

.cusradiolist li .anticon-check svg {
  color: #19a403;
}

.ambassodorBody {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

.ambsidebar {
  width: 255px;
  background: #2f327d;
  padding: 0 0px;
  margin: 0;
}

.ambassodormain {
  width: calc(100% - 255px);
  padding: 0;
  margin: 0;
  /* background: #fafafa; */
  background: #f8f8f8;
}

.sidebar-logo {
  text-align: center;
  padding: 20px 0 80px;
  margin: 0;
}

.ambmainwrap {
  padding: 20px 47px 0;
}

.ambcardpanel {
  padding: 0;
  margin: 0 0 40px;
}

.ambcardpanel .box {
  background: #ffffff;
  border-radius: 10px;
  margin: 0;
  height: 100%;
  padding: 32px;
  box-shadow: 0px 1px 6px 0px #ebebeb;
}

.ambcardpanel .box p {
  padding: 0;
  margin: 0 0 10px;
  color: #4b4b4b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: capitalize;
}

.ambcardpanel .box .copycode {
  padding: 0;
  margin: 0;
}

.ambcardpanel .box .copycode button {
  background: none;
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border: 1px solid #2f327d;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  margin: 0 auto;
  width: 100%;
  height: 39px;
  transition: 0.5s;
}

.ambcardpanel .box .copycode button:hover {
  box-shadow: -1px 2px 13px 0px #bdbdbe;
}

.ambcardpanel .box .copycode button span {
  color: #2f327d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex: 1 1 auto;
  text-align: left;
}

.ambcardpanel .box .copycode button img {
  flex: 0 0 auto;
}

.ambcardpanel .box .eartop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20% 0 0;
  margin: 0 0 10px;
}

.ambcardpanel .box .eartop h4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  color: #2f327d;
  padding: 0;
  margin: 0;
}

.ambcardpanel .box strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #4b4b4b;
}

.commtablearea {
  background: #fff;
  border-radius: 12px;
  padding: 0;
  margin: 0;
  box-shadow: 0px 1px 6px 0px #ebebeb;
}

.commtablearea .ttl {
  padding: 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commtablearea .ttl h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #4b4b4b;
  padding: 0;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(../images/select-arow-1.svg) !important;
  background-repeat: no-repeat !important;
  background-position: right 8px center !important;
  padding-right: 26px !important;
  background-size: 10px !important;
}

.commtablearea .ttl select {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 39px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  color: #374151;
  padding: 5px 10px;
}

.commtablearea .tablewrap table {
  width: 100%;
}

.commtablearea .tablewrap table thead th {
  border-top: 1px solid #f4f4f5;
  border-bottom: 1px solid #f4f4f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a1a1aa;
}

.commtablearea .tablewrap table th,
.commtablearea .tablewrap table td {
  padding: 15px 30px;
}

.commtablearea .tablewrap table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #27272a;
}

.tablepagi {
  padding: 30px;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.tablepagi ul {
  display: flex;
  align-items: center;
}

.tablepagi ul li {
  padding: 0;
  margin: 0;
}

.tablepagi ul li a {
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  color: #6b7280;
  padding: 10px 20px;
  display: inline-block;
}

.tablepagi ul li.nxp-btn a {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  color: #6b7280;
  padding: 10px 20px;
}

.tablepagi ul li {
  margin-right: 10px;
}

.tablepagi ul li:last-child {
  margin-right: 0px;
}

.tablepagi ul li a:hover,
.tablepagi ul li.page-active a {
  background: #2f327d;
  color: #fff !important;
}

.ambassodorHeader {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 47px;
  align-items: center;
}

.ambassodorHeader .ttlWrap {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.ambassodorHeader h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #4658ac;
  padding: 0;
  margin: 0;
}

.ambassodorHeader .rt-side {
  padding: 0;
  margin: 0;
}

.ambassodorHeader .rt-side {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 0 auto;
}

.ambassodorHeader .rt-side .cashOut {
  padding: 0;
  margin: 0 65px 0 0;
}

.ambassodorHeader .rt-side .cashOut button {
  background: #2f327d;
  border: 1px solid #2f327d;
  border-radius: 5px;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  color: #ffffff;
  padding: 8px 16px;
  outline: none;
  box-shadow: none;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 8px;
  transition: 0.5s;
}

.ambassodorHeader .rt-side .cashOut button:hover {
  box-shadow: 4px 5px 9px gray;
}

.ambassodorHeader .rt-side .notification {
  position: relative;
  padding: 0;
  margin: 0;
}

.ambassodorHeader .rt-side .notification span {
  background: #eb5757;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  right: -3px;
}

.ambsideitem {
  padding: 0 15px;
  margin: 0 0 13px;
}

.ambsideitem.logout-item {
  padding: 0 25px;
  cursor: pointer;
}

.ambsideitem .items {
  display: flex;
  align-items: center;
  column-gap: 11px;
}

.ambsideitem a {
  padding: 10px;
  border-radius: 5px;
  display: block;
  transition: all 300ms ease-in-out;
}

.ambsideitem a:hover,
.ambsideitem a.active {
  background: #f48c06;
}

.ambsideitem .items .menu-icon {
  width: 24px;
  padding: 0;
  flex: 0 0 auto;
}

.ambsideitem .items .menulabel p {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.ambsidebar .use-info {
  border-top: 2px solid rgba(255, 255, 255, 0.22);
  padding: 30px 15px;
  margin: 60px 0 0;
  display: flex;
  align-items: center;
}

.ambsidebar .use-info .tham-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}

.ambsidebar .use-info .tham-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 !important;
}

.ambsidebar .use-info .text {
  width: calc(100% - 40px);
  padding: 0 0 0 15px;
}

.ambsidebar .use-info .text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ambsidebar .use-info .text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #ffffff;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Timer {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 15px;
}

.blogtham {
  width: 150px;
}

.blogtham img {
  max-width: 100%;
}

.notificationtext {
  padding: 0 0 0 40px;
  margin: 0;
}

.notificationtext h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding: 0;
  margin: 0 0 15px;
}

.notificationtext p {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0 0 15px;
}

.notificationboxwrap {
  border-right: 1px solid #e5e5e5;
  padding: 0 40px 0 0;
  margin: 0;
  height: calc(100vh - 141px);
  overflow-y: auto;
}

.notificationboxwrap::-webkit-scrollbar {
  width: 0;
}

.notificationboxwrap::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 27px;
}

.notificationboxwrap::-webkit-scrollbar-thumb {
  background-color: rgb(10, 10, 113);
  border-radius: 27px;
}

.notificationboxwrap .notification-box {
  display: flex;
  padding: 10px 10px 10px 20px;
  margin: 0 0 30px;
}

.notificationboxwrap .notification-box .info {
  width: calc(100% - 99px);
}

.notificationboxwrap .notification-box .info h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 0;
  margin: 0 0 10px;
}

.notificationboxwrap .notification-box .info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notification-box .unreadbox {
  text-align: right;
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notification-box .unreadbox p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #cb077c;
  padding: 0;
  margin: 0 0 10px;
}

.notificationboxwrap .notification-box .unreadbox span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.7);
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notifibottomtext {
  display: flex;
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notifibottomtext .info {
  width: calc(100% - 99px);
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notifibottomtext .info h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 0;
  margin: 0 0 10px;
}

.notificationboxwrap .notifibottomtext .info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
}

.notificationboxwrap .notifibottomtext .rttext span,
.unreadbox span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.7);
  padding: 0;
  margin: 0;
}

.notification-date {
  display: flex;
  margin-top: 0px;
}

.notification-time {
  display: flex;
  margin-top: 10px;
}

.afterRead {
  background-color: white;
  border-left: none;
}

.calculator {
  /* border: 3px solid black;
    width: 245px; */
  /* z-index: 6; */
}

.calculator {
  width: 100%;
  max-width: 300px;
  margin: auto;
  background-color: #111;
  border-radius: 0.5rem;
  padding: 0.75rem;
  position: fixed;
  top: 50%;
  z-index: 1000;
  right: -300px;
  transform: translate(0px, -50%);
  transition: all 600ms ease-in-out;
}

.calculator_backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.calculator.active {
  right: 15px;
}

.calculator .caloutput {
  width: 100%;
  height: 100px;
  margin-bottom: 1rem;
  background-color: #222;
  border-radius: 0.5rem;
  color: #fff;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.calculator .caloutput p {
  font-size: 20px;
  line-height: 1.2;
  word-break: break-all;
}

.calculator .cal-btn-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 0.5rem;
}

.calculator .cal-btn-wrap button {
  width: 100%;
  height: 60px;
  border-radius: 0.25rem;
  border: 0;
  background-color: #222;
  color: #ececec;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  border: 1px solid #222;
}

.calculator .cal-btn-wrap button:hover {
  border-color: #f48c06;
}

.calculator .cal-btn-wrap button.cleartBtn {
  background-color: #f48c06;
}

.zenith-box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.zenith-box .tham {
  width: 41px;
}

.zenith-box .tham img {
  max-width: 100%;
}

.zenith-box .info {
  width: calc(100% - 41px);
  padding: 0 0 0 15px;
  margin: 0;
}

.zenith-box .info strong {
  display: block;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  color: #2f327d;
  padding: 0;
  margin: 0 0 0px;
}

.zenith-box .info p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #4b4b4b;
  padding: 0;
  margin: 0;
  text-align: left;
}

.ambcardpanel .box.autoHeight {
  min-height: auto;
}

.accountbtnbox .btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2f327d;
  padding: 0 26px;
  margin: 0;
  border: 1px solid #2f327d;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  transition: 0.5s;
}

.accountbtnbox .btn:hover {
  box-shadow: 4px 5px 9px gray;
}

.status-approve {
  color: #19a403;
}

.status-pending {
  color: #f48c06;
}

.status-declined {
  color: #ff0000;
}

.form-control-field {
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  width: 100%;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.form-group {
  margin: 0 0 15px;
}

.form-group label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6b6b6b;
  padding: 0;
  margin: 0 0 10px;
}

.settings-wrap {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  height: calc(100vh - 100px);
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.settings-wrap::-webkit-scrollbar {
  width: 0 !important;
}

.settings-wrap .ttl {
  border-bottom: 2px solid #e5e5e5;
  padding: 0 0 20px;
  margin: 0 0 30px;
}

.settings-wrap .ttl h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0;
}

.settings-wrap .formWrap {
  padding: 0 0 40px;
  margin: 0;
}

.switchPanel {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0 0 28px;
}

.switchPanel .ant-form-item-control-input-content {
  height: unset !important;
}

.switchPanel:last-child {
  margin-bottom: 0;
}

.switchPanel .text {
  padding: 7px 0 0;
  margin: 0;
}

body .ant-switch.ant-switch-checked {
  background: #edf2f9 !important;
}

.switchPanel .text p {
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.switchPanel .switch-box .ant-form-item {
  margin-bottom: 0 !important;
  margin-left: 20px;
}

.otpResend {
  background-color: white;
  font-weight: 600;
  color: #2f327d;
  margin: 0 0 0 2px;
}

.otpResend:disabled {
  background-color: white;
  font-weight: 600;
  color: #545799;
  margin: 0 0 0 2px;
  cursor: default !important;
}

.can-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  padding: 11px 24px;
  background: #fff;
}

.sd-save-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  border: 1px solid #2f327d;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  padding: 11px 24px;
  background: #2f327d;
}

.upload-panel {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 30px;
}

.upload-panel .uploadTham {
  height: 50px;
  width: 50px;
  border-radius: 3px;
}

.upload-panel .uploadTham img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-panel .uploadText input[type="file"] {
  display: none;
}

.upload-panel .uploadText label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #2f327d;
  padding: 0;
  margin: 0 0 0 15px;
}

.bar-chart-box {
  padding: 30px 0 0;
  margin: 0;
}

.bar-chart-box h3 {
  color: #000;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  margin: 0 0 20px;
}

.bar-chart {
  border-bottom: 1px solid;
  height: 200px;
  position: relative;
  margin: 0;
}

.bar-name {
  position: absolute;
  bottom: -22px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #000;
}

.bar {
  border: 1px solid #2f327d;
  width: inherit;
  height: 0%;
  position: absolute;
  bottom: 0px;
  background-color: #2f327d;
}

.bar .hr-chip {
  height: 100%;
  position: relative;
}

.bar .hr-chip p {
  position: absolute;
  top: -26px;
  left: -5px;
}

.bar .hr-chip p span {
  background: #ffba00;
  border-radius: 3px;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  width: 36px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar .hr-chip p span:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #ffba00;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(45deg);
}

.main-bar {
  height: 200px;
  width: calc(14.2857142857% - 10px);
  margin: 5px;
  display: inline-block;
}

.btnPanelWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.btnPanelWrap .btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  background: #444444;
  border-radius: 3px;
  padding: 10px 15px;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0 10px 10px 0;
  transition: all 300ms ease-in-out;
}

.btnPanelWrap .btn:last-child {
  margin-right: 0;
}

.btnPanelWrap .btn:hover {
  background: #6b6b6b;
}

.calculatorPanle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 30px 0;
}

.calculatorPanle .calculator-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0;
  margin: 0;
}

.calculatorPanle .calculator-box .text {
  padding: 0 0 0 10px;
  margin: 0;
}

.calculatorPanle .calculator-box .text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0;
}

.quit-main-wrap {
  padding: 0;
  margin: 0;
}

.quit-main-wrap p {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
  padding: 0;
  margin: 0 0 10px;
}

.quit-btn-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.quit-btn-panel .back-next-side {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.quit-btn-panel .back-next-side .btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  background: #444444;
  border-radius: 3px;
  padding: 10px 15px;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0 10px 0px 0;
  transition: all 300ms ease-in-out;
}

.quit-btn-panel .back-next-side .btn:last-child {
  margin-right: 0;
}

.quit-btn-panel .next-subject .btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  background: #444444;
  border-radius: 3px;
  padding: 10px 15px;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0 10px 0px 0;
  transition: all 300ms ease-in-out;
}

.quit-btn-panel .next-subject .btn:hover,
.quit-btn-panel .back-next-side .btn:hover {
  background: #6b6b6b;
}

.center-submit-btn {
  text-align: center;
}

.cashOutModalWrap {
  padding: 0;
  margin: 0;
}

.cashOutModalWrap .modal-ttl {
  text-align: center;
  padding: 16px 0 32px;
  margin: 0;
}

.cashOutModalWrap .modal-ttl h3 {
  padding: 0;
  margin: 0;
  color: #323232;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.cashOutModalWrap .form-group {
  margin-bottom: 20px;
}

.cashOutModalWrap .form-control {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.075px;
  height: 46px;
}

.cashOutModalWrap .form-control::placeholder {
  color: #6b7280;
}

.modal-btn-wrap {
  padding: 0;
  margin: 16px 0 0;
}

.modal-btn-wrap .btn {
  padding: 0px 8px;
  background: #2f327d;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #fff !important;
  outline: none;
  box-shadow: none;
  border: 1px solid #2f327d;
  transition: all 300ms ease-in-out;
  height: 48px !important;
  transition: 0.5s;
}

.modal-btn-wrap .btn:hover {
  box-shadow: 4px 5px 9px gray;
}

.ant-modal-close {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.change-account-modal .change-account-modal-ttl {
  padding: 36px 0 15px;
  margin: 0 0 30px;
  border-bottom: 1px solid #d2d2d2;
}

.change-account-modal .change-account-modal-ttl h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
  padding: 0;
  margin: 0;
}

.cus-ant-form .ant-row {
  flex-wrap: wrap;
}

.cus-ant-form .ant-form-item label {
  display: block !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #4f4f4f;
}

.cus-ant-form .ant-row .ant-col {
  width: 100%;
  text-align: left;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 100%;
}

.cus-ant-form .ant-row .ant-col .ant-input,
.cus-ant-form .ant-row .ant-col .ant-select-selector {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  outline: none !important;
  box-shadow: none;
  border-radius: 0;
}

.cus-ant-form .ant-row .ant-col .ant-form-item-control-input {
  width: 100%;
}

.cus-ant-form .ant-row .ant-col .ant-form-item-control-input .ant-input-password {
  padding: 0;
  border: none;
  box-shadow: none;
}

.transaction-info-modal .ant-modal-header {
  padding: 0px 0 15px;
  margin: 0 0 30px;
  border-bottom: 1px solid #d2d2d2;
}

.transaction-info-modal .ant-modal-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
  padding: 0;
  margin: 0;
}

.transaction-infowrap ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 0;
  margin: 0 0 15px;
}

.transaction-infowrap ul li span {
  min-width: 200px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #a1a1aa;
  padding: 0;
  margin: 0;
}

.transaction-infowrap ul li strong {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #27272a;
  padding: 0;
  margin: 0;
}

.otpmodal .otpmodal-logo {
  text-align: center;
  padding: 60px 0 40px;
  margin: 0;
}

.otpmodal h2 {
  margin: 30px 0 15px;
  font-weight: 500;
  font-size: 27px;
  text-transform: uppercase;
  line-height: 1.2;
  color: #000000;
  padding: 0;
}

.otpmodal h3 {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #393939;
  padding: 0px 0;
  margin: 0 0 20px;
}

.otpmodal p {
  text-align: center;
  font-weight: 400;
  font-size: 13.6295px;
  line-height: 1.2;
  color: #4f4f4f;
  padding: 0;
  margin: 0 0 30px;
}

.otpmodal p a {
  color: #2f327d;
}

.otpmodal h4 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  padding: 0px 0;
  margin: 0 0 20px;
}

.otpmodal-wrap {
  text-align: center;
  padding: 0;
  margin: 0;
}

.otpmodal-wrap .otpmodal-field-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 auto 15px;
  max-width: 350px;
}

.otpmodal-wrap .opt-field {
  background: #ffffff;
  /* border: 1px solid #2F327D; */
  border: 1px solid #969696;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  /* border-radius: 10px; */
  border-radius: 3.78598px;
  padding: 3px 10px;
  margin: 0 10px;
  outline: none !important;
  box-shadow: none;
  text-align: center;
  font-size: 20px;
  max-width: calc(16.6666667% - 20px);
  letter-spacing: 1.5px;
}

.otpmodal-wrap h5 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  padding: 10px 0 0;
  margin: 0 0 30px;
}

.otpmodal .otpmodal-wrap p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0 0 15px;
}

.otpmodal .otpmodal-wrap p a {
  cursor: pointer;
  font-weight: 600;
  color: #2f327d;
  text-decoration: underline !important;
}

.otpmodal-wrap h5 strong {
  color: #2f327d;
  cursor: pointer;
}

.otpmodal-wrap .error-msg {
  font-weight: 500;
  margin-bottom: 10px;
}

.otpmodal-wrap span.otptimer {
  /* font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    padding: 0;
    margin: 0 0 10px;
    display: inline-block; */
  font-weight: 600;
  color: #2f327d;
  margin: 0 0 0 10px;
}

.otpmodal-wrap .otpmodal-btn-panel {
  display: flex;
  justify-content: center;
}

.otpmodal-wrap .otpmodal-btn-panel .btn {
  background-color: #2f327d;
  border: none;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  border: 1px solid #2f327d;
  outline: none;
  box-shadow: none;
  text-transform: capitalize;
  padding: 12px 20px;
  transition: all 300ms ease-in-out;
  margin: 0 15px 0 0;
}

.otpmodal-wrap .otpmodal-btn-panel .btn:last-child {
  margin-right: 0;
}

.otpmodal-wrap .otpmodal-btn-panel .btn:hover {
  color: #2f327d;
  background: #fff;
}

.bar-chart {}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0.5px solid #dddddd;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 20px;
}

.go-to-dashboard {
  width: 50% !important;
}

.blog-read-more {
  color: #2f327d;
  text-decoration: underline !important;
}

.selectedSub {
  background: #00a859 !important;
}

.mobile-menu {
  display: none;
}

.subcribe-exam-wrap {
  width: 100%;
  padding: 20px;
}

.subcribe-exam-wrap .recommend-exam .image {
  text-align: center;
  width: 165px;
}

.subcribe-exam-wrap .recommend-exam {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.subcribe-exam-wrap .recommend-exam .info-recommend {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 165px);
  padding: 0 0px 0 15px;
}

.subcribe-exam-wrap .recommend-exam .info-recommend-rt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.subcribe-exam-wrap .recommend-exam .info-recommend-rt .select-btn-wrap .select-subject {
  width: 200px;
}

.subcribe-exam-wrap .recommend-exam .info-recommend-rt .btn-panel-box {
  padding: 0 0 0 20px;
}

.subcribe-exam-wrap .recommend-exam .info-recommend-rt .btn-panel-box .auth-btn {
  padding-left: 30px;
  padding-right: 30px;
}

.subcribe-exam-wrap .recommend-exam.subcribeExamPanel .recommend-exam-wrap {
  width: calc(100% - 610px);
}

.site-height-wrap .real-home {
  min-height: 100vh;
}

.ant-tabs .password-settings {
  padding: 0 15px;
}

.tablewrap {
  overflow-x: auto;
}

.listening-wrap .btn-box label {
  display: flex;
  align-items: center;
}

.radio-btn-wrap .btn-box label span.hd-icon {
  display: flex;
  height: 36px;
  width: 36px;
  border: 1px solid #2f327d;
  background: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.listening-panel {
  padding: 40px 0;
  margin: 0;
}

.listening-panel .listening-ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
}

.listening-panel .listening-ttl .tham-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2f327d;
  margin: 0 20px 0 0;
  border-radius: 50%;
}

.listening-panel .listening-ttl h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: #2f327d;
  padding: 0;
  margin: 0;
}

.instructions-sec {
  max-width: 745px;
  margin: 0 auto;
  padding: 40px 0 0;
}

.instructions-sec h2 {
  font-weight: 600;
  font-size: 47px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0 0 30px;
}

.instructions-sec .time-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #393939;
  padding: 0;
  margin: 0 0 30px;
  display: block;
}

.instructions-panel {
  padding: 0;
  margin: 0 0 40px;
}

.instructions-panel h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0 0 30px;
}

.instructions-panel ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #393939;
  padding: 0;
  margin: 0 0 15px;
}

.instructions-panel .important {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  padding: 60px 0 0;
}

.ielts-wrapper {
  margin: 0 auto;
  overflow-y: hidden;
}

.ielts-simulator-header {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding: 20px;
}

.ielts-header-logo {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.ielts-header-timer {
  display: flex;
  align-items: center;
}

.ielts-header-btn {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
}

.ielts-header-back-btn {
  margin-right: 30px;
  background-color: white;
  color: black;
  padding: 10px 24px;
}

.ielts-header-submit-btn {
  background-color: white;
  color: black;
  padding: 10px 24px;
}

.ielts-instructions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px;
}

.ielts-heading {
  padding-left: 90px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
}

.ielts-wrapper {
  display: flex;
  flex-direction: column;
}

.left-instructions {
  width: 52%;
  /* border-right:3px solid #E7E7E7; */
  margin-right: 5px;
  margin-left: 50px;
  padding-right: 20px;
}

.left-right-border {
  border: 1px solid #e7e7e7;
}

.left-instructions h4 {
  text-align: center;
}

.suggestions-top {
  margin-top: 10px;
}

.suggestion-point {
  margin-top: 10px;
}

.section-info {
  display: flex;
  align-items: center;
}

.right-suggestions-points {
  display: flex;
  margin-top: 10px;
}

.right-instructions {
  width: 48%;
  margin-left: 30px;
}

.ielts-footer {
  margin-top: 20px;
}

.select-points {
  margin-left: 10px;
}

.q-set-sec {
  padding: 81px 0 0;
  margin: 0;
}

.q-set-top-bar {
  background: #231f20;
  padding: 0 30px;
  margin: 0;
}

.q-set-top-bar .q-set-top-bar-wrap {
  display: flex;
  justify-content: space-between;
  background: #d9d9d9;
  align-items: center;
  padding: 15px 30px;
  margin: 0;
}

.q-set-top-bar .q-set-top-bar-wrap .set-number {
  padding: 0;
  margin: 0;
}

.q-set-top-bar .q-set-top-bar-wrap .set-number strong {
  font-weight: 700;
  font-size: 81.9396px;
  line-height: 82px;
  color: #000000;
  display: inline-block;
}

.q-set-ttl {
  padding: 0 30px;
  margin: 0 0 0px;
}

.q-set-ttl .q-set-ttl-wrap {
  padding: 30px 30px;
  margin: 0 0 30px;
  border-bottom: 1px solid #444444;
}

.q-set-ttl h2 {
  font-size: 30px;
  line-height: 1.2;
  color: #2c1c1f;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.q-set-ttl p {
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  color: #2c1c1f;
  padding: 0;
  margin: 0;
}

.q-set-desc {
  padding: 30px 60px;
  margin: 0;
}

.q-set-desc p {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  padding: 0;
  margin: 0;
}

.q-set-btn {
  font-size: 16px;
  line-height: 1.2;
  background: #000000;
  padding: 10px 25px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  color: #fff;
}

.q-set-wraper {
  padding: 30px 60px;
  margin: 0;
}

.q-set-wraper ol {
  list-style: revert;
}

.q-set-wraper ol li {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #000000;
  padding: 0;
  margin: 0;
}

.q-set-btn-wraper {
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.q-set-btn-wraper .continue-btn {
  font-size: 17px;
  line-height: 20px;
  background: #6c6d71;
  color: #fff;
  text-transform: uppercase;
  padding: 6px 30px 6px 10px;
  position: relative;
  border: none;
  box-shadow: none;
  outline: none;
}

.q-set-btn-wraper .submit-btn {
  font-size: 17px;
  line-height: 20px;
  background: #6c6d71;
  color: #fff;
  text-transform: uppercase;
  padding: 6px 20px 6px 20px;
  position: relative;
  border: none;
  box-shadow: none;
  outline: none;
}

.q-set-btn-wraper .continue-btn.back-btn {
  padding: 6px 10px 6px 30px;
  margin: 0 15px 0 0;
}

.q-set-btn-wraper .continue-btn:after {
  content: "";
  position: absolute;
  right: -25px;
  top: -15px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-left: 40px solid #6c6d71;
  border-bottom: 30px solid transparent;
}

.q-set-btn-wraper .continue-btn.back-btn:after {
  left: -25px;
  right: inherit;
  transform: rotate(180deg);
}

.q-set-img {
  padding: 0;
  margin: 0;
}

.q-set-img img {
  max-width: 100%;
}

.border-rt-dott {
  border-right: 1px dashed #676161;
}

.q-set-wraper .q-set-text-wrap {
  padding: 30px 0 0;
  margin-top: 30px;
  border-top: 1px solid #444444;
}

.q-set-wraper h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  padding: 0 0 60px;
  margin: 0;
}

.q-set-wraper i {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  font-style: italic;
  padding: 0;
  margin: 0 0 30px;
}

.q-set-timing-box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.q-set-timing-box span {
  display: inline-block;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.2;
  padding: 0 0 0 10px;
  margin: 0;
}

.instruction-sec {
  padding: 81px 0 30px;
  max-width: 1199px;
  margin: 0 auto;
}

.instruction-sec .instruction-wrap {
  padding: 100px 0 0;
  margin: 0;
  max-width: 740px;
}

.instruction-sec .instruction-wrap h2 {
  font-weight: 500;
  font-size: 75px;
  line-height: normal;
  color: #231d1f;
  padding: 0 0 32px;
  margin: 0 0 32px;
  border-bottom: 13px solid #0a4c89;
}

.instruction-sec .instruction-wrap p {
  font-size: 24px;
  line-height: 1.2;
  color: #231d1f;
  padding: 0;
  margin: 0 0 60px;
}

.instruction-sec .instruction-wrap .start-btn {
  font-size: 17px;
  line-height: 20px;
  background: #2f327d;
  color: #fff;
  text-transform: uppercase;
  padding: 6px 30px 6px 10px;
  position: relative;
  border: none;
  box-shadow: none;
  outline: none;
}

.instruction-sec .instruction-wrap .start-btn:after {
  content: "";
  position: absolute;
  right: -25px;
  top: -15px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-left: 40px solid #2f327d;
  border-bottom: 30px solid transparent;
}

.ielts-sim-header {
  padding: 20px;
  background: #000000;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ielts-sim-header .sim-ttl h3 {
  font-weight: 600;
  font-size: 28;
  line-height: 1.2;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.ielts-sim-header .sim-time p {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.2;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.ielts-sim-header .sim-time p span {
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 0 4px;
}

.ielts-sim-header .sim-btn-wrap {
  display: flex;
  align-items: center;
}

.ielts-sim-header .sim-btn-wrap .btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  background: #fff;
  padding: 10px 24px;
  margin: 0 15px 0 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.ielts-sim-header .sim-btn-wrap .btn:last-child {
  margin-right: 0;
}

.sim-main-ttl {
  padding: 30px 20px;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
}

.bor-solid-rt {
  border-right: 1px solid #e7e7e7;
}

.sim-main-body {
  padding: 0 20px;
  margin: 0;
}

.sim-main-body .task-box {
  max-width: 600px;
  padding: 50px 0 30px;
}

.ds-mrl {
  margin-left: 40px;
}

.sim-main-body .task-box h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin: 0 0 20px;
}

.sim-main-body .task-box .job-details-list li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0 0 15px;
}

.sim-main-body .task-box .job-details-list li strong {
  font-weight: 600;
}

.sim-main-body .task-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #373737;
  padding: 0;
  margin: 0 0 15px;
}

.sim-main-body .task-box h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  padding: 0 0 30px;
  margin: 0;
}

.sim-main-body .task-box .bg-table {
  width: 100%;
  overflow-x: auto;
}

.sim-main-body .task-box .bg-table table {
  background: #d9d9d9;
  border: 1px solid #000;
}

.sim-main-body .task-box .bg-table table td {
  padding: 10px;
  border-right: 1px solid #000;
}

.sim-main-body .task-box .bg-table table tr {
  border-bottom: 1px solid #000;
}

.sim-main-body .task-box .bg-table table tr:last-child {
  border-bottom: none;
}

.sim-main-body .task-box .bg-table table td:last-child {
  border-right: none;
}

.sim-main-body .task-box ol {
  list-style: none;
  padding-left: 0;
}

.sim-main-body .task-box ol.qui-list li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #373737;
  /* display: flex; */
  align-items: center;
  padding: 0 0 10px;
  margin: 0;
}

.sim-main-body .task-box ol.qui-list li small {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #373737;
  padding: 0 10px 0 0;
  margin: 0;
  width: 30px;
  display: inline-block;
}

.sim-main-body .task-box ol.qui-list li select {
  height: 27px;
  border: 1px solid #5c5c5c;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  width: 95px;
  margin: 0 10px 0 0;
}

.sim-main-body .task-box .back-next-btn {
  display: flex;
  align-items: center;
  padding: 30px 0 0;
}

.sim-main-body .task-box .back-next-btn .btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  background: #2f327d;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  color: #fff;
  padding: 8px 24px;
  margin: 0 15px 0 0;
  border: 1px solid #2f327d;
}

.sim-main-body .task-box .back-next-btn .btn.back-btn {
  background: #fff;
  color: #2f327d;
}

.sim-main-body .task-box .back-next-btn .btn:last-child {
  margin-right: 0;
}

.task-menu-bar {
  border-top: 1px solid #e7e7e7;
  padding: 20px;
  margin: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  left: 0;
}

.task-menu-bar button {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  padding: 10px 20px;
  margin: 0;
  border-radius: 3px;
  background: transparent;
}

.task-menu-bar button.active {
  background: #444444;
  color: #fff;
}

.ielts-sim-body {
  padding: 0 0 81px;
  margin: 0;
}

.para-q-text {
  padding: 0;
  margin: 0 0 30px;
}

.para-q-text .num-ttl {
  background: #d1d2d4;
  padding: 0;
  margin: 0 0 30px;
}

.para-q-text .num-ttl h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 42px;
  background: #231f20;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.para-q-text p {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  padding: 0;
  margin: 0 0 30px;
}

.para-q-text ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #000000;
  padding: 0;
  margin: 0;
}

.sound-checking-sec {
  max-width: 1366px;
  margin: 0 auto;
  padding: 30px 15px;
}

.sound-checking-sec .sound-checking-ttl {
  text-align: center;
  padding: 0 0 60px;
  margin: 0;
  position: relative;
}

.sound-checking-sec .sound-checking-ttl h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #000;
  padding: 0;
  margin: 0;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress {
  position: absolute;
  right: 0;
  top: 0;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"]:focus {
  outline: none;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #1e1e1e;
  border-radius: 1px;
  border: 0px solid #000000;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #1e1e1e;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #1e1e1e;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #1e1e1e;
}

.sound-checking-sec .sound-checking-ttl .volume-checking-progress input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #1e1e1e;
  border-radius: 1px;
  border: 0px solid #000000;
}

/* input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
}
input[type=range]:focus::-ms-fill-lower {
    background: #888;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
} */

.sound-checking-items {
  padding: 0;
  margin: 0;
}

.sound-checking-items .sound-checking-item {
  text-align: center;
  padding: 0;
  margin: 0;
}

.sound-checking-items .sound-checking-item .sound-checking-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 auto;
  background: #d9d9d9;
}

.sound-checking-items .sound-checking-item .sound-checking-info {
  padding: 20px 0 0;
  margin: 0;
}

.sound-checking-items .sound-checking-item .sound-checking-info p {
  font-size: 14px;
  line-height: 1.6;
  padding: 0;
  margin: 0 0 10px;
}

.sound-checking-items .sound-checking-item .sound-checking-info span {
  display: block;
  font-size: 14px;
  line-height: 1.6;
  padding: 0;
  margin: 0 0 10px;
}

.sound-checking-items .sound-checking-item .sound-checking-btn .btn {
  font-size: 14px;
  line-height: 1.6;
  padding: 6px 20px;
  border-radius: 3px;
  color: #fff;
  background: #2f327d;
  margin: 0 10px 0 0;
}

.sound-checking-items .sound-checking-item .sound-checking-btn .btn:last-child {
  margin-right: 0;
}

.sound-checking-sec .bottom-btn {
  text-align: center;
  padding: 80px 0 0;
  margin: 0;
}

.sound-checking-sec .bottom-btn .btn {
  width: 100%;
  max-width: 764px;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  padding: 20px;
  background: #2f327d;
  margin: 0 auto;
  border: none;
  box-shadow: none;
}

.subscribe-btn {
  border: 1px solid var(--secondary-color);
  align-items: center;
  padding: 8px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  background: #fff;
  color: #2f327d;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
}

.ielts-qu-detils {
  padding: 30px 20px;
}

.ielts-qu-detils h3 {
  font-size: 20px;
  line-height: 1.2;
  color: #000;
  font-weight: 600;
  padding: 0 0 20px;
  margin: 0 0 0px;
}

.ielts-qu-detils p {
  font-size: 16px;
  line-height: 1.6;
  color: #373737;
  padding: 0;
  margin: 0 0 10px;
}

.table-responsive {
  overflow-x: auto;
  padding: 0;
  margin: 0 0 30px;
}

.table-responsive table {
  border: 1px solid #000;
  background: #e4e4e4;
}

.table-responsive table td,
.table-responsive table th {
  padding: 10px 10px;
  font-size: 16px;
  color: #373737;
}

.table-responsive table thead th {
  text-align: center;
  border-bottom: 1px solid #000;
}

.table-responsive table tr td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.table-responsive table tr td:last-child {
  border-right: none;
}

.table-responsive table tr:last-child td {
  border-radius: none;
}

.from-qu-list {
  padding: 0;
  margin: 0;
}

.from-qu-list li {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.2;
  color: #373737;
}

.from-qu-list li p {
  color: #373737;
  padding: 0;
  margin: 0;
}

.from-qu-list li .list-form-con {
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  height: 36px;
  padding: 5px 10px;
  font-size: 16px;
  margin: 0;
}

.ant-form-item-explain-error {
  text-align: left !important;
}

.ant-checkbox-wrapper {
  align-items: flex-start;
}

.signin-container .auth-btn.login--btn {
  height: 44px !important;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 10px;
}

.ant-checkbox-wrapper .ant-checkbox~span {
  color: #909090;
  font-size: 13.63px;
  font-style: normal;
  font-weight: 400;
}

body div.height-unset,
body .height-unset,
body .height-unset .ant-form-item-control-input,
body .height-unset .ant-form-item-control-input-content {
  min-height: unset !important;
  height: unset !important;
}

/*  start: menu toogle */
.menu-toggle {
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  flex: 0 0 auto;
  border: 0;
}

.menu-toggle div {
  width: 100%;
  height: 2.5px;
  border-radius: 3px;
  background-color: #2f327d;
  position: relative;
  transition: 0.5s;
}

.menu-toggle div::before,
.menu-toggle div::after {
  content: "";
  position: absolute;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: #2f327d;
  border-radius: 3px;
  transition: 0.5s;
}

.menu-toggle div::before {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

.menu-toggle div::after {
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}

.menu-toggle.active div {
  background-color: transparent !important;
}

.menu-toggle.active div:after {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.menu-toggle.active div::before {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

/*  end: menu toogle */

.no-data-show-msg-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 16px;
  padding: 60px 0 24px;
}

.no-data-show-msg-sec .icon-container {}

.no-data-show-msg-sec .icon-container img {
  width: 100px;
}

.no-data-show-msg-sec .action-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 1025px) {
  .no-data-show-msg-sec .action-container.for-responsive {
    display: none !important;
  }
}

.no-data-show-msg-sec .action-container .back-btn {
  padding: 10px 42px;
  background: #2f327d;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.no-data-show-msg-sec .action-container .back-btn img {
  width: 17px;
  flex: 0 0 auto;
}

.no-data-show-msg-sec .text-container {
  color: #6b7280;
  text-align: center;
  font-size: 23.471px;
  font-style: normal;
  font-weight: 500;
  line-height: 35.206px;
  letter-spacing: 0.117px;
}

/* start: caseOut-modal */
.caseOut-modal * {
  box-sizing: border-box;
}

.caseOut-modal {
  width: calc(100% - 30px) !important;
  max-width: 450px !important;
}

.caseOut-modal .ant-modal-content {
  padding: 24px 37px 38px;
}

/* end: caseOut-modal */
/* start: settings-wrap */
.settings-wrap .settings-row {
  display: flex;
  column-gap: 42px;
  align-items: flex-start;
  justify-content: space-between;
}

.settings-wrap .settings-row .left-content {
  width: 100%;
  max-width: calc(100% - 245px);
}

@media (min-width: 1025px) {
  .settings-wrap .for-responsive {
    display: none !important;
  }
}

.settings-wrap .settings-row .right-actions {
  width: 100%;
  max-width: 245px;
  margin-left: auto;
}

.settings-wrap .settings-row .right-actions .sideBtn {
  display: flex;
  column-gap: 18px;
}

.settings-wrap .settings-row .right-actions .sideBtn .custom-btn {
  transition: 0.5s;
  width: 114px;
  padding: 0 8px;
  height: 40px;
}

.settings-wrap .settings-row .right-actions .sideBtn .custom-btn:hover {
  box-shadow: 4px 5px 9px gray;
}

.settings-wrap .left-content .form-group {
  margin: 0 0 32px;
}

.settings-wrap .left-content .form-group .ant-input {
  border-radius: 3px !important;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.settings-wrap .left-content .form-group .ant-input::placeholder {
  font-size: 14px;
}

.settings-wrap .left-content .form-group .ant-form-item-label,
.settings-wrap .left-content .form-group .ant-form-item-label label {
  margin: 0;
  color: #6b6b6b;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.settings-wrap .left-content .form-group .ant-form-item-control-input-content .ant-input-group .ant-input {
  height: 48px !important;
}

/* end: settings-wrap */
/* start: contactUs-sec */
.contactUs-sec {}

.contactUs-sec .contactUs-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 90px;
  align-items: center;
}

.contactUs-wrapper .left-div {
  width: 100%;
  max-width: calc(100% - 546px);
}

.contactUs-wrapper .right-div {
  width: 100%;
  max-width: 546px;
}

.contactUs-wrapper .right-div img {
  width: 100%;
  object-fit: cover;
}

.contactUs-wrapper .left-div .ant-card {
  border-radius: 16px;
}

.contactUs-wrapper .left-div .ant-card-body {
  padding: 24px 32px 42px;
}

.contactUs-wrapper .left-div .ant-card-body h3 {
  color: #061c3d;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.288px;
  margin: 0;
}

.contactUs-wrapper .left-div .ant-card-body h5 {
  color: #464646;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 0;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form {
  margin-top: 32px;
}

.contactUs-wrapper .ant-card-body .ant-form .ant-form-item {}

.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item .ant-form-item-label,
.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item .ant-form-item-label label {
  margin: 0;
  color: #061c3d;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item .ant-form-item-control-input-content .ant-input {
  border-radius: 3px !important;
  border: 1px solid #e6e8ec !important;
  color: #838e9e !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 0 16px !important;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item .ant-form-item-control-input-content .ant-input::placeholder {
  font-size: 16px !important;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item .ant-form-item-control-input-content .ant-input:not(:disabled) {
  background: #fff;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form .form-actions {
  display: block;
  margin-top: 20px;
}

.ant-form-item.for-textarea .ant-form-item-control-input-content input.ant-input,
.contactUs-wrapper .left-div .ant-card-body .ant-form .ant-form-item.for-textarea .ant-form-item-control-input-content .ant-input {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  resize: none;
}

.contactUs-wrapper .left-div .ant-card-body .ant-form .form-actions .ant-btn {
  width: 100%;
  height: 47px !important;
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* end: contactUs-sec */
/* start: caseOut-alert-modal */
.caseOut-alert-modal {
  width: calc(100% - 30px) !important;
  max-width: 432px !important;
}

.caseOut-alert-modal .ant-modal-content {
  padding: 32px 16px 38px;
}

.caseOut-alert-modal .cashOutModalWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.caseOut-alert-modal .cashOutModalWrap .icon-container {}

.caseOut-alert-modal .modal-btn-wrap {
  margin-top: 26px;
}

.caseOut-alert-modal .modal-btn-wrap .ant-btn {
  width: 168px;
  padding: 10px 12px;
  border-radius: 3px;
  background: #2f327d;
  color: #fff !important;
  transition: 0.5s;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.075px;
}

.caseOut-alert-modal .modal-btn-wrap .ant-btn:hover {
  box-shadow: 4px 5px 9px gray;
}

.caseOut-alert-modal .cashOutModalWrap .text-container {
  margin-top: 20px;
}

.caseOut-alert-modal .cashOutModalWrap .text-container h3 {
  color: #1f2937;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  margin: 0;
}

.caseOut-alert-modal .cashOutModalWrap .text-container p {
  margin: 12px 0 0;
  color: #6b7280;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08px;
}

/* end: caseOut-alert-modal */
/* start: fix student dashboard */
.site-height-wrap.for-student .header .user-info-wrap {
  width: 325px;
}

.site-height-wrap.for-student .header .center-search-box {
  width: 100%;
  max-width: 605px;
  position: relative;
}

.site-height-wrap.for-student .header .center-search-box .ant-input-prefix {}

.site-height-wrap.for-student .header .center-search-box .ant-input-prefix svg {
  width: 18px;
  height: auto;
}

.site-height-wrap.for-student .header .center-search-box .ant-input {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.site-height-wrap.for-student .header .center-search-box .ant-input::placeholder {
  color: #7f97bd;
  opacity: 1;
}

.site-height-wrap.for-student .sidebar {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #2f327d gray;
  scrollbar-width: thin;
}

.site-height-wrap.for-student .sidebar::-webkit-scrollbar {
  width: 8px;
  background: #dcdcdc;
  height: 100%;
}

.site-height-wrap.for-student .sidebar::-webkit-scrollbar-thumb {
  background: #2f327d;
  border-radius: 4px;
}

.site-height-wrap.for-student .sidebar .sideitem {
  width: 100% !important;
}

.site-height-wrap.for-student .sidebar .sideitem.log-out-li {
  margin: 80px 0 24px;
}

.site-height-wrap.for-student .sidebar .sideitem.log-out-li a {
  color: #f00;
  cursor: pointer;
}

.site-height-wrap.for-student .sidebar .sideitem a {}

.home .home-left .exam .exambtnpanel .start-buy {
  padding: 8px 10px !important;
  width: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
}

.home .home-left .exam .exambtnpanel .start-buy p {
  margin: 0 !important;
  padding: 0 !important;
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exam-lists {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  row-gap: 24px;
}

.exam-lists .exam-list-col {}

.home .home-left .exam-lists .exam-list-col .exam {
  margin: 0;
}

.home .home-left .exam-lists .exam-list-col .exam .examinfo .image {
  border: 0;
  padding: 16px;
  background-color: #014823;
}

.home .home-left .exam-lists .exam-list-col .exam .examinfo .image img {
  width: 100%;
  object-fit: contain;
  background-color: #fff;
  border-radius: 5px !important;
}

/* end: fix student dashboard */
.right-blog-lists {
  display: flex;
  flex-direction: column;
}

.right-blog-lists .right-blog-list-item:first-child {
  padding-top: 0 !important;
}

.right-blog-lists .right-blog-list-item {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
}

.right-blog-lists .right-blog-list-item .image-container {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  flex: 0 0 auto;
  /* background-color: #2F327D !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #2f327d;
  text-transform: uppercase;
  font-size: 18px;
}

.right-blog-lists .right-blog-list-item .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.right-blog-lists .right-blog-list-item .text-container {}

.right-blog-lists .right-blog-list-item .text-container h4 {
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.right-blog-lists .right-blog-list-item .text-container p {
  color: #8a8a8a;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.std-body-without-right-panel {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 32px;
}

.pro-gen-settings .submit-actions {
  display: flex;
  justify-content: flex-end;
}

.pro-gen-settings .submit-actions .save-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  width: 295px;
  height: 47px !important;
}

.ant-tabs .password-settings .inner-container {
  width: 100%;
  max-width: 570px;
}

.ant-tabs .password-settings .submit-actions {
  display: flex;
  column-gap: 16px;
  margin-top: 50px;
}

.ant-tabs .password-settings .submit-actions .custom-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  height: 47px;
  margin: 0;
  width: 188px;
}

.password-settings {
  margin-top: 28px;
}

.pro-gen-settings .top-heading-with-right-action {
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.pro-gen-settings .top-heading-with-right-action h5 {
  margin: 0;
  color: #242424;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pro-gen-settings .top-heading-with-right-action .cancel-btn {
  color: #f00;
  font-size: 16px;
  background-color: transparent;
  font-weight: 400;
}

.outlet .contactUs-sec {
  padding: 0 0 0 24px;
}

.outlet .contactUs-sec .contactUs-wrapper {
  column-gap: 40px;
  align-items: flex-start;
}

.outlet .contactUs-wrapper .right-div {
  max-width: 700px;
}

.contactUs-wrapper .left-div {
  max-width: 550px;
  padding: 48px 0 0;
  margin: 0 auto;
}

.sure-modal .ant-modal-content {
  border: 2px solid rgba(30, 126, 229, 1);
}

.sure-modal .btn-wrap {
  display: flex;
  justify-content: space-between;
}

.sure-modal .ant-modal-close {
  display: none !important;
}

/* start: .skeleton-loading */
.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.skeleton-loading *:empty {
  background: #f6f7f8;
  background-position: -500px 0;
  animation: skeletonShine 1s linear 0s infinite normal forwards;
  background-image: linear-gradient(135deg,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
}

@keyframes skeletonShine {
  to {
    background-position: 500px 0;
  }
}

.skeleton-form-control {
  height: 50px;
}

.skeleton-loading .form-group {
  margin-bottom: 24px;
}

/* end: .skeleton-loading */
.no-exam-find-msg {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 32px 10px;
}

.no-exam-find-msg .icon-container {}

.no-exam-find-msg .icon-container {
  max-width: 100%;
}

.no-exam-find-msg .msg-container {
  width: 100%;
  max-width: 350px;
  margin: 16px auto 0;
}

.no-exam-find-msg .msg-container h4 {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  color: #333 !important;
}

.no-exam-find-msg .msg-container p {
  color: #888 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  margin: 10px 0 0 !important;
}

.no-exam-find-msg .msg-container .action {
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
}

.no-exam-find-msg .msg-container .action .register-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #2f327d;
  background: #fff;
  outline: none;
  padding: 10px 24px;
  color: #2f327d;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  min-width: 200px;
  transition: 0.5s;
}

.no-exam-find-msg .msg-container .action .register-btn:hover {
  box-shadow: 4px 5px 9px gray;
}

/* start: student_dashb_header */
.student_dashb_header .menu-toggle div {
  background-color: #000000;
}

.student_dashb_header .menu-toggle div::before,
.student_dashb_header .menu-toggle div::after {
  background-color: #000000;
}

.student_dashb_header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

.sign-in-btn {
  /* width: 100%; */
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: 1px solid #eee !important;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.20000000298023224px;
  /* text-align: left; */
  color: #111a24 !important;
}

.sign-in-btn-icon {
  width: 24px;
  height: 24px;
}

/* end: student_dashb_header */

.pricing__box {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: start;
  margin: -40px auto 0;
  align-items: center;
}

.pricing__home {
  width: max-content;
  height: 25px;
  padding: 5px 14px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #8a8a8a66;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #2f327d;
}

.pricing__box--modal {
  margin: 20px auto 0;
}

.pricing__item {
  width: 100%;
  border: 1px solid rgba(47, 50, 125, 0.15);
  border-radius: 8px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: start;
  box-shadow: 0px 9.11px 22.79px 0px rgba(0, 0, 0, 0.08);
  color: var(--secondary-color);
}

.pricing__item--recommended {
  background-color: var(--secondary-color);
  color: var(--white-color);
  box-shadow: 0px 9.11px 22.79px 0px rgba(255, 255, 255, 0.08);
  position: relative;
}

.pricing__recommendation {
  position: absolute;
  right: 22px;
  top: 22px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.pricing__recommendation>span {
  font-family: Work Sans;
  font-size: 10px;
  font-weight: 600;
  line-height: 9.77px;
  text-align: left;
  color: #f48c06;
}

.pricing__title {
  font-family: Work Sans;
  font-size: 18.61px;
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: 0.025em;
  text-align: center;
  margin: 10px auto;
}

.pricing__price {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
}

.pricing__price>h3 {
  font-family: Work Sans;
  font-size: 30.65px;
  font-weight: 600;
  line-height: 29.96px;
  text-align: left;
}

.pricing__price>p {
  font-family: Work Sans;
  font-size: 14.58px;
  font-weight: 600;
  line-height: 17.11px;
  text-align: left;
}

.pricing__info {
  width: 75%;
  margin: 0 auto;
  font-family: Product Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.56px;
  text-align: center;
  color: #506375;
}

.pricing__btn {
  background-color: var(--secondary-color);
  color: var(--white-color);
  width: 100%;
  border-radius: 6px;
  padding: 14px 10px;
  font-family: Manrope;
  font-size: 14.58px;
  font-weight: 800;
  line-height: 19.92px;
  text-align: center;
}

.pricing__item--recommended>.pricing__btn {
  background-color: var(--white-color);
  color: var(--secondary-color);
}

.predicted__questions,
.predicted_exam {
  margin-top: 100px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.predicted_exam {
  gap: 32px;
}

.predicted_exam__questions {
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: start;
}

.predicted_exam__info {
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #de0000;
}

.predicted_exam__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.predicted__questions__subjects {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 48px;
}

.predicted__questions__subjects--list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 14px;
  align-items: start;
}

.predicted__questions__subjects h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.89px;
  letter-spacing: 0.02em;
  text-align: left;
}

.future_questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: start;
}

.future_questions__heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}

.future_questions__heading h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: left;
  color: var(--secondary-color);
}

.future_questions__heading p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: left;
  width: 80%;
  color: #506375;
}

.future_questions__box {
  display: flex;
  flex-direction: column;
  gap: 23px;
  width: 100%;
}

.future_question {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.future_question__start {
  width: 60px;
  height: 60px;
  border: 1px solid var(--secondary-color);
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  justify-content: center;
  background-color: transparent;
}

.future_question__start span {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: var(--secondary-color);
  text-transform: capitalize;
}

.future_question__start img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
}

.future_question__exam {
  display: flex;
  align-items: center;
  gap: 36px;
}

.future_question__image__box {
  width: 184px;
  height: 128px;
  border: 1px solid #c8cdd6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.future_question__image {
  width: 124px;
  height: 88.34px;
  object-position: center;
  object-fit: cover;
}

.future_question__info {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 261px;
  gap: 12px;
}

.future_question__year {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: left;
  color: #a7afbd;
}

.future_question__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26.04px;
  text-align: left;
  color: #2b2b2b;
}
